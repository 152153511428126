// src/components/Newsletter.tsx
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
// import toast, { Toaster } from "react-hot-toast";

 import { ToastContainer, toast } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css";

const schema = z.object({
  first_name: z.string({ message: "First name is required" }),
  email: z.string().email({ message: "Invalid email address" }),
  protection_toggle: z.string(),
});

// https://github.com/withastro/astro/issues/12283
// tweak / shim for astro error but doesnt fix it
// const RETURN_NULL = false;

type Inputs = z.infer<typeof schema>;
//
interface ApiResponse {
  message: string;
  success?: boolean;
}
//
// this came from astro component
// const {
//   label = "Search",
//   // title = Astro.currentLocale === "fr" ? "S'abonner" : "Subscribe",
//   title = "Subscribe",
//   id = "footer-input",
// } = Astro.props;

// interface Props {
//   label?: string;
//   title?: string;
//   id?: string;
// }

// const placeholder = Astro.currentLocale === "fr" ? "Entrez votre email" : "Enter your email";
const placeholder = "Enter your first name";
const placeholder2 = "Enter your email";
//

// const

// const validationEndpoint = "/api/rate-limiter";

export default function NewsletterInputForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
  });

  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (!!error) {
    console.log("this is an error: ", error);
  }

  // tweak / shim for astro error but doesnt fix it
  // if (RETURN_NULL) {
  //   return null;
  // }

  const onSubmit = async (data: Inputs) => {
    setIsLoading(true);

    try {
      // Rate limiting check
      const rateLimitResponse = await fetch("/api/rate-limiter-newsletter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const rateLimitData = await rateLimitResponse.json();
      // console.log("this is the rate limit data request: ", rateLimitData);

      if (!rateLimitResponse.ok) {
        setError(rateLimitData.error);
        toast.error(
          rateLimitData.error || "Too many requests. Please try again later.",
        );
        return;
      }

      // Newsletter subscription
      const subscribeResponse = await fetch("/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const subscribeData: ApiResponse = await subscribeResponse.json();

      if (!subscribeResponse.ok) {
        throw new Error(subscribeData.message || subscribeResponse.statusText);
      }

      toast.success(subscribeData.message);
      reset();
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "Failed to submit email";
      console.error("Submission error:", error);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  // const onSubmit = async (data: Inputs) => {
  //   //
  //   setLoading(true);
  //   //
  //   console.log("this is the input data for email: ", data);
  //   try {
  //     const res = await fetch("/api/subscribe", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     if (!res.ok) {
  //       throw new Error("Something went wrong");
  //     }

  //     const resData = await res.json();
  //     console.log("this is the resData: ", resData);

  //     toast.success(resData.message);
  //     reset();
  //   } catch (error) {
  //     console.error("this is the error: ", error);
  //     toast.error("Failed to submit email");
  //   } finally {
  //     setLoading(false);
  //   }
  //   // setLoading(false);
  // };

  // const onSubmit = async (data: Inputs) => {
  //   setIsLoading(true);

  //   // outer try-catch block
  //   try {
  //     console.log("this is the input data: ", data);
  //     //
  //     // First API call - rate limit checker
  //     // First API call - Validation
  //     try {
  //       const response_rate_limiter = await fetch("/api/rate-limiter", {
  //         method: "POST",
  //         // body: new FormData(event.target),
  //         body: JSON.stringify(data),
  //       });

  //       if (!response_rate_limiter.ok) {
  //         const data = await response_rate_limiter.json();
  //         setError(data.error);

  //         // later use "too many requests, please try again later"
  //         toast.error(data.error);
  //         return data;
  //       }

  //       // Success handling
  //       console.log("this is the successful api response", response_rate_limiter);
  //       // //
  //       const response_subscriber = await fetch("/api/subscribe", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(data),
  //       });

  //       const responseData: ApiResponse = await response_subscriber.json();

  //       if (!response_subscriber.ok) {
  //         throw new Error(responseData.message || response_subscriber.statusText);
  //       }

  //       toast.success(responseData.message);
  // Reset form after successful submission
  //   reset();
  // } catch (error) {
  //   setError("An error occurred");
  // }
  //
  // First API call - Validation
  // const validationResponse = await fetch(validationEndpoint, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   // body: JSON.stringify(validationData),
  //   // body: JSON.stringify({ "foo": "bar" }),
  //   body: JSON.stringify(data),
  // });

  // if (!validationResponse.ok) {
  //   throw new Error(`Validation failed: ${validationResponse.statusText}`);
  // }

  // const validationResult: ValidationResponse =
  //   await validationResponse.json();

  // // Check validation result
  // if (!validationResult.isValid) {
  //   throw new Error(validationResult.message || "Validation failed");
  // }
  // //
  // const response = await fetch("/api/subscribe", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(data),
  // });

  // const responseData: ApiResponse = await response.json();

  // if (!response.ok) {
  //   throw new Error(responseData.message || response.statusText);
  // }

  // toast.success(responseData.message);
  // Reset form after successful submission
  //     reset();
  //   } catch (error) {
  //     const errorMessage =
  //       error instanceof Error ? error.message : "Failed to submit email";

  //     console.error("Submission error:", error);
  //     toast.error(errorMessage);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  return (
    <>
      {/* <div className="min-h-[20vh] w-full bg-gray-600 py-6">
        <div className="container mx-auto px-4 py-6">
          <div className="mx-auto grid max-w-6xl items-center gap-10 lg:grid-cols-2"> */}
      {/* Left Column - Title */}
      {/* <div>
              <h1 className="mb-4 text-3xl font-bold text-gray-900 lg:text-3xl">
                Sign up for product launch announcements and our future monthly
                newsletter...
              </h1>
            </div> */}

      {/* Right Column - Form */}
      {/* <div className="rounded-lg bg-gray-800 p-6"> */}

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <ToastContainer />
        {/* <Toaster
          // client:load
          position="bottom-right"
          toastOptions={{
            duration: 5000,
          }}
        /> */}
        <div className="mt-4 flex flex-col items-center gap-2 rounded-lg bg-neutral-200 p-2 dark:bg-neutral-800 sm:flex-row sm:gap-3">
          <div className="w-full space-y-4">
            {/* Added space-y-4 for vertical spacing between inputs */}
            <div>
              {/* Wrapped first input in its own div */}
              <label htmlFor="first-name" className="sr-only">
                First Name
              </label>
              <input
                id="first_name"
                type="text"
                placeholder={placeholder}
                {...register("first_name")}
                disabled={loading}
                className="w-full rounded-md border border-gray-300 px-4 py-2 focus:border-transparent focus:ring-2 focus:ring-orange-500"
              />
              {errors.first_name && (
                <p className="mt-1 text-sm text-red-500">
                  {errors.first_name.message}
                </p>
              )}
            </div>
            <div>
              {/* Wrapped second input in its own div */}
              {/* <label htmlFor="footer-input" className="sr-only"> */}
              <label htmlFor="email_address" className="sr-only">
                Search
              </label>
              <input
                id="email"
                type="text"
                placeholder={placeholder2}
                {...register("email")}
                disabled={loading}
                // id="email"
                // name="email"
                className="block w-full rounded-lg border-transparent bg-neutral-100 px-4 py-3 text-sm text-neutral-600 caret-orange-400 focus:border-orange-400 focus:ring-orange-400 disabled:pointer-events-none disabled:opacity-50 dark:border-transparent dark:bg-neutral-700 dark:text-gray-300 dark:placeholder:text-neutral-300"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-500">
                  {errors.email.message}
                </p>
              )}
            </div>
          </div>

          {/* <a
            className="inline-flex w-full items-center justify-center gap-x-2 whitespace-nowrap rounded-lg border border-transparent bg-orange-400 p-3 text-sm font-bold text-neutral-50 outline-none ring-zinc-500 transition duration-300 hover:bg-orange-500 focus-visible:ring disabled:pointer-events-none disabled:opacity-50 dark:ring-zinc-200 dark:focus:outline-none dark:focus:ring-1 sm:w-auto"
            href="#"
          >
            Subscribe
          </a> */}
          <input
            id="protection_toggle"
            type="text"
            {...register("protection_toggle", { required: false })}
            style={{ display: "none" }}
          />
          <button
            type="submit"
            disabled={loading}
            className="inline-flex w-full items-center justify-center gap-x-2 whitespace-nowrap rounded-lg border border-transparent bg-orange-400 p-3 text-sm font-bold text-neutral-50 outline-none ring-zinc-500 transition duration-300 hover:bg-orange-500 focus-visible:ring disabled:pointer-events-none disabled:opacity-50 dark:ring-zinc-200 dark:focus:outline-none dark:focus:ring-1 sm:w-auto"
            // className="bg-orange-700 hover:bg-orange-800 w-full rounded-md px-4 py-2 font-medium text-white disabled:cursor-not-allowed disabled:opacity-50"
          >
            {loading ? "Sending..." : "Sign Up Now"}
          </button>
          {/* <Toaster position="bottom-right" /> */}
        </div>
      </form>

      {/* </div> */}
    </>
  );
}

// export NewsletterInputForm;
